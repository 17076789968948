import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classes from "./Edit.module.css";
import { fire } from "../Firebase";
import { useDispatch } from "react-redux";
import { loginActions } from "../Store/login";
import "../index.css";
import Loader from "../Components/Loader";
import Navbar from "../Components/Navbar";

const ActiveOrder = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [entData, setEntData] = useState({});
  const [activeOrders, setActiveOrders] = useState([]);
  const [entActiveOrders, setEntActiveOrders] = useState([]);
  const [getOrder, setGetOrder] = useState(false);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const userPresent = localStorage.getItem("username");
    if (userPresent) {
      dispatch(loginActions.isUserPresent({ user: userPresent }));
    }
  }, [dispatch]);

  // Orders Data Fetching

  useEffect(() => {
    fire.child("activeOrders").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
    };
  }, []);

  useEffect(() => {
    fire.child("entActiveOrders").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setEntData({ ...snapshot.val() });
      } else {
        setEntData({});
      }
    });
    return () => {
      setEntData({});
    };
  }, []);

  const undeliveredOrder = [];
  const userArray = [];
  const entUndeliveredOrder = [];
  const entArray = [];

  useEffect(() => {
    const entryValue = Object.entries(data);
    for (const statuss of entryValue) {
      if (statuss[1]?.status !== "Delivered") undeliveredOrder.push(statuss);
    }

    for (let i = 0; i < undeliveredOrder.length; i++) {
      const newOrderArrayKey = undeliveredOrder[i]?.[1];
      const newOrderArray = undeliveredOrder[i]?.[0];

      newOrderArrayKey.key = newOrderArray;
      userArray.push(newOrderArrayKey);
    }

    const entEntryValue = Object.entries(entData);
    for (const statuss of entEntryValue) {
      if (statuss[1]?.status !== "Delivered") entUndeliveredOrder.push(statuss);
    }

    for (let i = 0; i < entUndeliveredOrder.length; i++) {
      const newOrderArrayKey = entUndeliveredOrder[i]?.[1];
      const newOrderArray = entUndeliveredOrder[i]?.[0];

      newOrderArrayKey.key = newOrderArray;
      entArray.push(newOrderArrayKey);
    }

    const reverseArrayList = (arr) => {
      arr.reverse();
      return arr;
    };

    const reverseActiveOrders = reverseArrayList(userArray);
    const reverseDeliveredOrders = reverseArrayList(entArray);

    setActiveOrders(reverseActiveOrders);
    setEntActiveOrders(reverseDeliveredOrders);

    setLoader(false);
    setGetOrder(true);
  }, [data, entData]);

  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  return (
    <>
      <Navbar />
      {loader && <Loader />}

      {getOrder && (
        <div className={classes.container}>
          <div className={classes.orderLength}>
            <h3>{activeOrders.length} Orders in User</h3>
          </div>
          {/* <table className={classes.styledTable}>
            <thead>
              <tr>
                <th>User</th>
                <th>Time</th>
                <th>Pickup </th>
                <th>Pickup No</th>
                <th>Pickup Ref</th>
                <th>Drop </th>
                <th>Drop No</th>
                <th>Drop Ref</th>
                <th>Secure Amount</th>
                <th>Status</th>
                <th>Price</th>
                <th>Payment</th>
                <th>Delivery Boy</th>
                <th>Edit</th>
                <th>Package Ref</th>
                <th>Route</th>
                <th>Access</th>
              </tr>
            </thead>
            <tbody>
              {activeOrders.map((id) => {
                return (
                  <tr key={id.key}>
                    <td>{id.userEmail}</td>
                    <td>{id.dateTime.slice(9)}</td>
                    <td>{truncate(id.pickAdd, 40)}</td>
                    <td
                                         >
                      {id.pickNo}
                    </td>
                    <td>{id.pickRem}</td>
                    <td>
                      {truncate(id.dropAdd, 40)}
                      <br /> <br />
                      {truncate(id.dropAdd1, 40)}
                      <br /> <br />
                      {truncate(id.dropAdd2, 40)}
                    </td>
                    <td>
                      <span
                        onClick={() => {
                          window.location.href = `tel:${id.dropNo}`;
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {id.dropNo}
                      </span>
                      <br /> <br />
                      <span
                        onClick={() => {
                          window.location.href = `tel:${id.dropNo1}`;
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {id.dropNo1}
                      </span>
                      <br /> <br />
                      <span
                        onClick={() => {
                          window.location.href = `tel:${id.dropNo2}`;
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {id.dropNo2}
                      </span>
                    </td>
                    <td>
                      {id.dropRem}
                      <br /> <br />
                      {id.dropRem1}
                      <br /> <br />
                      {id.dropRem2}
                    </td>
                    <td>{id.secPack}</td>
                    <td>
                      {" "}
                      {id.rider}
                      <br />
                      <br />
                      {id.status}
                    </td>
                    <td>
                      {id.kilometers}Km
                      <br />
                      <br />
                      Rs-{id.price}
                    </td>
                    <td>{id.payment}</td>
                    <td>{id.deliveryBoy}</td>
                    <td>
                      <Link to={`/updateactive/${id.key}`}>
                        <button className={classes.editBtn}>Edit</button>
                      </Link>
                    </td>
                    <td>{id.packRef}</td>
                    <td>
                      <button
                        onClick={() => {
                          const startPoint = id.pickAdd;
                          const endPoint = id.dropAdd;

                          const mapsUri = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
                            startPoint
                          )}&destination=${encodeURIComponent(endPoint)}`;
                          window.location.href = mapsUri;
                        }}
                        className={classes.editBtn}
                      >
                        Maps
                      </button>
                      {id.dropAdd1?.length === 0 ? (
                        ""
                      ) : (
                        <button
                          onClick={() => {
                            const startPoint = id.dropAdd;
                            const endPoint = id.dropAdd1;

                            const mapsUri = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
                              startPoint
                            )}&destination=${encodeURIComponent(endPoint)}`;
                            window.location.href = mapsUri;
                          }}
                          className={classes.editBtn}
                          style={{ marginTop: "20px" }}
                        >
                          Maps1
                        </button>
                      )}
                      {id.dropAdd2?.length === 0 ? (
                        ""
                      ) : (
                        <button
                          onClick={() => {
                            const startPoint = id.dropAdd1;
                            const endPoint = id.dropAdd2;

                            const mapsUri = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
                              startPoint
                            )}&destination=${encodeURIComponent(endPoint)}`;
                            window.location.href = mapsUri;
                          }}
                          className={classes.editBtn}
                          style={{ marginTop: "20px" }}
                        >
                          Maps2
                        </button>
                      )}
                    </td>
                    <td>{id.access}</td>
                  </tr>
                );
              })}
            </tbody>
            <div className={classes.profile}>
              <h3>{entActiveOrders.length} Orders in Enterprise</h3>
            </div>
            <tbody>
              {entActiveOrders.map((id) => {
                return (
                  <tr key={id.key}>
                    <td>{id.userEmail}</td>
                    <td>{id.dateTime.slice(9)}</td>
                    <td>{id.pickAdd}</td>
                    <td
                      onClick={() => {
                        window.location.href = `tel:${id.pickNo}`;
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {id.pickNo}
                    </td>
                    <td>{id.pickRem}</td>
                    <td>
                      {id.dropAdd}
                      <br /> <br />
                      {id.dropAdd1}
                      <br /> <br />
                      {id.dropAdd2}
                    </td>
                    <td>
                      <span
                        onClick={() => {
                          window.location.href = `tel:${id.dropNo}`;
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {id.dropNo}
                      </span>
                      <br /> <br />
                      <span
                        onClick={() => {
                          window.location.href = `tel:${id.dropNo1}`;
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {id.dropNo1}
                      </span>
                      <br /> <br />
                      <span
                        onClick={() => {
                          window.location.href = `tel:${id.dropNo2}`;
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {id.dropNo2}
                      </span>
                    </td>
                    <td>
                      {id.dropRem}
                      <br /> <br />
                      {id.dropRem1}
                      <br /> <br />
                      {id.dropRem2}
                    </td>
                    <td>{id.secPack}</td>
                    <td>
                      {" "}
                      {id.rider}
                      <br />
                      <br />
                      {id.status}
                    </td>
                    <td>
                      {id.kilometers}Km
                      <br />
                      <br />
                      Rs-{id.price}
                    </td>
                    <td>{id.payment}</td>

                    <td>{id.deliveryBoy}</td>
                    <td>
                      <Link to={`/entupdateactive/${id.key}`}>
                        <button className={classes.editBtn}>Edit</button>
                      </Link>
                    </td>
                    <td>{id.packRef}</td>
                    <td>
                      <button
                        onClick={() => {
                          const startPoint = id.pickAdd;
                          const endPoint = id.dropAdd;

                          const mapsUri = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
                            startPoint
                          )}&destination=${encodeURIComponent(endPoint)}`;
                          window.location.href = mapsUri;
                        }}
                        className={classes.editBtn}
                      >
                        Maps
                      </button>
                      {id.dropAdd1?.length === 0 ? (
                        ""
                      ) : (
                        <button
                          onClick={() => {
                            const startPoint = id.dropAdd;
                            const endPoint = id.dropAdd1;

                            const mapsUri = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
                              startPoint
                            )}&destination=${encodeURIComponent(endPoint)}`;
                            window.location.href = mapsUri;
                          }}
                          className={classes.editBtn}
                          style={{ marginTop: "20px" }}
                        >
                          Maps1
                        </button>
                      )}
                      {id.dropAdd2?.length === 0 ? (
                        ""
                      ) : (
                        <button
                          onClick={() => {
                            const startPoint = id.dropAdd1;
                            const endPoint = id.dropAdd2;

                            const mapsUri = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
                              startPoint
                            )}&destination=${encodeURIComponent(endPoint)}`;
                            window.location.href = mapsUri;
                          }}
                          className={classes.editBtn}
                          style={{ marginTop: "20px" }}
                        >
                          Maps2
                        </button>
                      )}
                    </td>
                    <td>{id.access}</td>
                  </tr>
                );
              })}
            </tbody>
          </table> */}
          <div className={classes.orders}>
            {activeOrders.map((id) => {
              return (
                <div className={classes.order}>
                  <div className={classes.pickupDetails}>
                    <div className={classes.pickAddNo}>
                      <p style={{ marginTop: "-10px" }}>{id.dateTime}</p>
                      <p>{truncate(id.pickAdd, 35)}</p>
                      <p
                        onClick={() => {
                          window.location.href = `tel:${id.pickNo}`;
                        }}
                        style={{ cursor: "pointer", fontWeight: 500 }}
                      >
                        {id.pickNo}
                      </p>
                    </div>
                    <div className={classes.pickTime}>
                      <p>{id.orderId}</p>
                      <div className={classes.pickStatusRider}>
                        <div
                          className={`${classes.pickStatus} ${
                            id.status === "Placed"
                              ? classes.blue
                              : classes.green
                          }`}
                        ></div>
                        <div className={classes.pickRider}>
                          {id.rider === "Express Rider" ? "🧍🏻" : "🧍🏻🧍🏻"}
                        </div>
                      </div>

                      <div className={classes.pickupTime}>{id.pickupTime}</div>
                    </div>
                  </div>
                  <div className={classes.dropDetails}>
                    <div className={classes.dropAddNo}>
                      <p>{truncate(id.dropAdd, 35)}</p>
                      <p
                        onClick={() => {
                          window.location.href = `tel:${id.dropNo}`;
                        }}
                        style={{ cursor: "pointer", fontWeight: 500 }}
                      >
                        {id.dropNo}
                      </p>
                    </div>
                    <div className={classes.distancePrice}>
                      <p>{id.kilometers} KM</p>
                      <p>Rs {id.price}</p>
                    </div>
                  </div>
                  <div>{id.cod === "" ? "" : `COD = ${id.cod}`}</div>
                  <div className={classes.moreDrop}>
                    {id.dropAdd1?.length !== 0 && id.dropAdd2?.length === 0 ? (
                      <div className={classes.moreDrop1}>
                        <Link to={`/updateactive/${id.key}`}>
                          <span>Multiple Drop:</span>
                          <span>+1 ➡️</span>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}

                    {id.dropAdd1?.length !== 0 && id.dropAdd2?.length !== 0 ? (
                      <div className={classes.moreDrop2}>
                        <Link to={`/updateactive/${id.key}`}>
                          <span>Multiple Drop:</span>
                          <span>+2➡️</span>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={classes.mapsEdit}>
                    <button
                      onClick={() => {
                        const startPoint = id.pickAdd;
                        const endPoint = id.dropAdd;

                        const mapsUri = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
                          startPoint
                        )}&destination=${encodeURIComponent(endPoint)}`;
                        window.location.href = mapsUri;
                      }}
                      className={classes.editBtn}
                    >
                      Maps
                    </button>
                    {id.dropAdd1?.length === 0 ? (
                      ""
                    ) : (
                      <button
                        onClick={() => {
                          const startPoint = id.dropAdd;
                          const endPoint = id.dropAdd1;

                          const mapsUri = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
                            startPoint
                          )}&destination=${encodeURIComponent(endPoint)}`;
                          window.location.href = mapsUri;
                        }}
                        className={classes.editBtn}
                      >
                        Maps1
                      </button>
                    )}
                    {id.dropAdd2?.length === 0 ? (
                      ""
                    ) : (
                      <button
                        onClick={() => {
                          const startPoint = id.dropAdd1;
                          const endPoint = id.dropAdd2;

                          const mapsUri = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
                            startPoint
                          )}&destination=${encodeURIComponent(endPoint)}`;
                          window.location.href = mapsUri;
                        }}
                        className={classes.editBtn}
                      >
                        Maps2
                      </button>
                    )}
                    <Link to={`/updateactive/${id.key}`}>
                      <p className={classes.edit}>
                        Edit<span className={classes.editIcon}> ➡️</span>
                      </p>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
          <h3 className={classes.orderLength}>
            {entActiveOrders.length} Orders in Enterprise
          </h3>

          <div className={classes.orders}>
            {entActiveOrders.map((id) => {
              return (
                <div className={classes.order}>
                  <div className={classes.pickupDetails}>
                    <div className={classes.pickAddNo}>
                      <p style={{ marginTop: "-10px" }}>{id.dateTime}</p>
                      <p>{truncate(id.pickAdd, 35)}</p>
                      <p
                        onClick={() => {
                          window.location.href = `tel:${id.pickNo}`;
                        }}
                        style={{ cursor: "pointer", fontWeight: 500 }}
                      >
                        {id.pickNo}
                      </p>
                    </div>
                    <div className={classes.pickTime}>
                      <p>{id.orderId}</p>

                      <div className={classes.pickStatusRider}>
                        <div
                          className={`${classes.pickStatus} ${
                            id.status === "Placed"
                              ? classes.blue
                              : classes.green
                          }`}
                        ></div>
                        <div className={classes.pickRider}>
                          {id.rider === "Express Rider" ? "🧍🏻" : "🧍🏻🧍🏻"}
                        </div>
                      </div>

                      <div className={classes.pickupTime}>{id.pickupTime}</div>
                    </div>
                  </div>
                  <div className={classes.dropDetails}>
                    <div className={classes.dropAddNo}>
                      <p>{truncate(id.dropAdd, 35)}</p>
                      <p
                        onClick={() => {
                          window.location.href = `tel:${id.dropNo}`;
                        }}
                        style={{ cursor: "pointer", fontWeight: 500 }}
                      >
                        {id.dropNo}
                      </p>
                    </div>
                    <div className={classes.distancePrice}>
                      <p>{id.kilometers} KM</p>
                      <p>Rs {id.price}</p>
                    </div>
                  </div>
                  <div>{id.cod === "" ? "" : `COD = ${id.cod}`}</div>
                  <div className={classes.moreDrop}>
                    {id.dropAdd1?.length !== 0 && id.dropAdd2?.length === 0 ? (
                      <div className={classes.moreDrop1}>
                        <Link to={`/entupdateactive/${id.key}`}>
                          <span>Multiple Drop:</span>
                          <span>+1 ➡️</span>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}

                    {id.dropAdd1?.length !== 0 && id.dropAdd2?.length !== 0 ? (
                      <div className={classes.moreDrop2}>
                        <Link to={`/entupdateactive/${id.key}`}>
                          <span>Multiple Drop:</span>
                          <span>+2➡️</span>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={classes.mapsEdit}>
                    <button
                      onClick={() => {
                        const startPoint = id.pickAdd;
                        const endPoint = id.dropAdd;

                        const mapsUri = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
                          startPoint
                        )}&destination=${encodeURIComponent(endPoint)}`;
                        window.location.href = mapsUri;
                      }}
                      className={classes.editBtn}
                    >
                      Maps
                    </button>
                    {id.dropAdd1?.length === 0 ? (
                      ""
                    ) : (
                      <button
                        onClick={() => {
                          const startPoint = id.dropAdd;
                          const endPoint = id.dropAdd1;

                          const mapsUri = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
                            startPoint
                          )}&destination=${encodeURIComponent(endPoint)}`;
                          window.location.href = mapsUri;
                        }}
                        className={classes.editBtn}
                      >
                        Maps1
                      </button>
                    )}
                    {id.dropAdd2?.length === 0 ? (
                      ""
                    ) : (
                      <button
                        onClick={() => {
                          const startPoint = id.dropAdd1;
                          const endPoint = id.dropAdd2;

                          const mapsUri = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
                            startPoint
                          )}&destination=${encodeURIComponent(endPoint)}`;
                          window.location.href = mapsUri;
                        }}
                        className={classes.editBtn}
                      >
                        Maps2
                      </button>
                    )}
                    <Link to={`/entupdateactive/${id.key}`}>
                      <p className={classes.edit}>
                        Edit<span className={classes.editIcon}> ➡️</span>
                      </p>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ActiveOrder;
